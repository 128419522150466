<template>
  <v-card>
    <v-card-title class="text-h6">{{ $t("labels.reject") }}</v-card-title>
    <v-card-text>
      {{
        $t("messages.goods_production_requirement_reject", {
          tracking: this.item.tracking_id,
        })
      }}
    </v-card-text>

    <v-card-text>
      <v-textarea
        v-model="note"
        class="c-input-small"
        @keyup.enter="confirm"
        :label="$t('labels.reason')"
        autofocus
        dense
        outlined
        clearable
        hide-details
        rows="3"
      ></v-textarea>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RequirementDetail",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    note: null,
  }),
  computed: {
    isDisabledBtn() {
      return !this.note || this.note.length < 100;
    },
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm", this.note);
      this.close();
    },
  },
};
</script>

<style scoped></style>
