import { render, staticRenderFns } from "./RequirementReject.vue?vue&type=template&id=2c7448b2&scoped=true"
import script from "./RequirementReject.vue?vue&type=script&lang=js"
export * from "./RequirementReject.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c7448b2",
  null
  
)

export default component.exports